import { format } from 'date-fns';

type Input = Date | string | undefined;

export const formatDateTime = (date: Input, formatString: string): string => {
  if (!date) {
    return '';
  }
  const dateObj = new Date(date);
  return format(dateObj, formatString);
};

/** Returns a user-friendly representation of a date only (without time), TODO: with respect to user's settings & locale.
 *
 * Falsy input yields an empty string.
 *
 * @param date instance of Date or an ISO 8601 timestamp string
 * @returns string with date representation, that can be used to render dates
 */
export const getOnlyDateRepresentation = (date: Input): string => formatDateTime(date, 'dd/MM/yyyy');

/** Returns a user-friendly representation of a date + time, TODO: with respect to user's settings & locale.
 *
 * Falsy input yields an empty string.
 *
 * @param date instance of Date or an ISO 8601 timestamp string
 * @returns string with date+time representation, that can be used to render dates
 */
export const getDateTimeRepresentation = (date: Input): string => formatDateTime(date, 'dd/MM/yyyy HH:mm');

/** Returns a user-friendly representation of a current date + time, TODO: with respect to user's settings & locale.
 *
 * @returns string with date+time representation, that can be used to render dates
 */
export const getCurrentDateRepresentation = (): string => getOnlyDateRepresentation(new Date());

export const getTimeAgo = (dateString: string) => {
  // Get the current timestamp in milliseconds
  const difference = Date.now() - new Date(dateString).getTime();

  // Handle invalid dates gracefully
  if (Number.isNaN(difference)) {
    return 'Invalid date';
  }

  // Define thresholds for different time units (in milliseconds)
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const year = day * 365;

  // Calculate the time unit and its corresponding value
  if (difference < minute) {
    const seconds = Math.floor(difference / second);
    return seconds === 0 ? 'just now' : `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
  }

  if (difference < hour) {
    const minutes = Math.floor(difference / minute);
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  }

  if (difference < day) {
    const hours = Math.floor(difference / hour);
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  }

  if (difference < month) {
    const days = Math.floor(difference / day);
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  }

  if (difference < year) {
    const months = Math.floor(difference / month);
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  }

  const years = Math.floor(difference / year);
  return `${years} year${years !== 1 ? 's' : ''} ago`;
};
