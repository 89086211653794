import { FC, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { CompareArrows, Link as LinkIcon, Visibility, VisibilityOff } from '@mui/icons-material';

const EFFECTS = [
  {
    id: 'LINK',
    name: 'Link',
    color: '#53B1FD',
    textColor: '#175CD3',
    background: '#EFF8FF',
    icon: <LinkIcon sx={{ color: '#53B1FD' }} />,
  },
  {
    id: 'FOLLOW_UP',
    name: 'Follow-up',
    color: '#3CCB7F',
    textColor: '#087443',
    background: '#EDFCF2',
    icon: <CompareArrows sx={{ color: '#3CCB7F' }} />,
  },
  {
    id: 'SHOW',
    name: 'Show',
    color: '#9B8AFB',
    textColor: '#5925DC',
    background: '#F4F3FF',
    icon: <Visibility sx={{ color: '#9B8AFB' }} />,
  },
  {
    id: 'HIDE',
    name: 'Hide',
    textColor: '#344054',
    color: '#98A2B3',
    background: '#F9FAFB',
    icon: <VisibilityOff sx={{ color: '#98A2B3' }} />,
  },
];

type VariableEffectProps = {
  type: string;
  isSmall?: boolean;
  onClick?: (id: string) => void;
};

const VariableEffect: FC<VariableEffectProps> = ({ type, isSmall = false, onClick }) => {
  const effect = useMemo(() => {
    return EFFECTS.find((eff) => eff.id === type) ?? EFFECTS[0];
  }, [type]);

  const handleClick = (id: string) => {
    if (onClick) {
      return onClick(id);
    }

    return undefined;
  };

  const sxStyles = isSmall
    ? { width: '35px', background: effect.background, borderRadius: 2, p: 0.5 }
    : {
        minWidth: '80px',
        border: `1px solid ${effect.color}`,
        background: effect.background,
        borderRadius: 2,
        cursor: 'pointer',
        p: 1,
      };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={sxStyles}
      onClick={() => handleClick(effect.id)}
    >
      {effect.icon}
      {!isSmall && (
        <Typography variant="body2" fontWeight="bold" color={effect.textColor} ml={0.5}>
          {effect.name}
        </Typography>
      )}
    </Box>
  );
};

export default VariableEffect;
