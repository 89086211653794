import { FC, useEffect, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { Avatar } from 'components/atoms';

type ActiveUserAvatarProps = {
  size?: number;
  avatarImage?: any;
};

const ActiveUserAvatar: FC<ActiveUserAvatarProps> = ({ size, avatarImage }) => {
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);

  const {
    user: { avatar, firstName, lastName },
  } = useAuth();

  useEffect(() => {
    if (avatarImage) {
      if (typeof avatarImage === 'string') {
        setImageSrc(avatarImage);
      } else if (avatarImage instanceof Blob) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const dataUrl = event.target?.result as string;
          setImageSrc(dataUrl);
        };
        reader.readAsDataURL(avatarImage);
      }
    }
  }, [avatarImage]);

  return (
    <Avatar src={imageSrc} firstName={firstName} lastName={lastName} color={avatar?.backgroundColor} size={size} />
  );
};

export default ActiveUserAvatar;
