import { ChangeEvent, FC, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { Publish } from '@mui/icons-material';
import ImageEditor from 'modals/ImageEditor';
import { ActiveUserAvatar, SingleFileUpload } from 'components/molecules';

const AvatarUploader: FC = () => {
  const [avatarFile, setAvatarFile] = useState<File | string | null>(null);
  const [editedImage, setEditedImage] = useState<File | string>();
  const [imageEditorOpen, setImageEditorOpen] = useState<boolean>(false);
  const [avatarUploading, setAvatarUploading] = useState<boolean>(false);

  const handleSelectFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setAvatarFile(file);
      setImageEditorOpen(true);
    }
  };

  const handleCloseImageEditor = () => {
    setImageEditorOpen(false);
    setAvatarFile(null);
  };

  const handleUploadAvatar = async (data: any) => {
    setEditedImage(data);
    setAvatarUploading(true);
    setImageEditorOpen(false);
    setAvatarUploading(false);
  };

  return (
    <Box sx={{ width: '360px', display: 'flex' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <Stack>
          <ActiveUserAvatar avatarImage={editedImage} size={80} />
        </Stack>

        <SingleFileUpload
          handleSelectFile={handleSelectFile}
          buttonText="Click to upload"
          acceptedFileTypes=".jpg, .png"
          icon={<Publish />}
          buttonType="outlined"
        />
      </Box>

      <ImageEditor
        file={avatarFile as File}
        onEditDone={handleUploadAvatar}
        open={imageEditorOpen}
        onClose={handleCloseImageEditor}
        loading={avatarUploading}
      />
    </Box>
  );
};

export default AvatarUploader;
