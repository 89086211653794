import { FC, ReactNode } from 'react';
import { Box, Stack, Tab, Tabs, styled } from '@mui/material';

import { MenuArrowLeftIcon, MenuArrowRightIcon } from 'assets/icons';

const StyledTab = styled(Tab)(({ theme: { palette } }) => ({
  minWidth: 0,
  padding: 0,
  '&.Mui-selected': { backgroundColor: '#DAEBFB' },
  '& svg': { width: 24, height: 24 },
  borderBottom: `1px solid ${palette.divider}`,
}));

const StyledTabsContainer = styled(Stack)(({ theme: { palette } }) => ({
  width: 50,
  border: `1px solid ${palette.divider}`,
  borderBottom: 'none',
}));

type CollapsiblePanelWithIconsProps = {
  expanded: boolean;
  children: ReactNode;
  childrenIconButtons?: {
    name: string;
    text: string;
    icon: JSX.Element;
    index: number;
    hidden?: boolean;
    disabled?: boolean;
  }[];
  selectedTab: number;
  onToggle: (expanded: boolean) => void;
  onTabSelection: (event: React.SyntheticEvent, tabIndex: number) => void;
};

const CollapsiblePanelWithIcons: FC<CollapsiblePanelWithIconsProps> = ({
  expanded,
  children,
  childrenIconButtons,
  onToggle,
  onTabSelection,
  selectedTab = 0,
}) => {
  return (
    <Box sx={{ display: 'flex', flexGrow: 1, bgcolor: 'background.paper' }}>
      <StyledTabsContainer>
        <StyledTab
          icon={expanded ? <MenuArrowLeftIcon /> : <MenuArrowRightIcon />}
          onClick={() => {
            onToggle?.(!expanded);
          }}
          sx={{ opacity: 1 }}
        />

        <Tabs
          value={selectedTab}
          onChange={onTabSelection}
          orientation="vertical"
          TabIndicatorProps={{
            sx: {
              left: 0,
            },
          }}
        >
          {childrenIconButtons &&
            childrenIconButtons.map((item) => (
              <StyledTab
                key={item.name}
                icon={item.icon}
                disabled={item.disabled}
                title={item.text}
                onClick={(e) => {
                  onTabSelection(e, item.index);
                  if (!expanded) {
                    onToggle(true);
                  }
                }}
              />
            ))}
        </Tabs>
      </StyledTabsContainer>

      {expanded && <Box width={400}>{children}</Box>}
    </Box>
  );
};

export default CollapsiblePanelWithIcons;
