import { FC, ReactNode } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${spacing(1)} ${spacing(2)}`,
    height: 49,
    borderTop: `1px solid ${palette.grey[200]}`,
    borderBottom: `1px solid ${palette.grey[200]}`,
  },
}));

type PanelHeaderProps = {
  title?: string;
  children?: ReactNode;
};

const PanelHeader: FC<PanelHeaderProps> = ({ title, children }) => {
  const { container } = useStyles();
  return (
    <Box className={container}>
      {title && (
        <Typography variant="subtitle1" color="primary">
          {title}
        </Typography>
      )}

      {children}
    </Box>
  );
};

export default PanelHeader;
