import { useState, useEffect, useRef, FocusEvent, ChangeEvent, FC, KeyboardEvent } from 'react';
import { Grid, FormHelperText, Theme, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button } from 'components/atoms';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    marginBottom: theme.spacing(2.5),
  },
  input: {
    width: '100%',
    height: theme.spacing(10),
    padding: theme.spacing(3),
    borderRadius: 4,
    border: 'solid 2px #bcbcbc',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.common.white,
    fontSize: theme.spacing(6),
    color: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'center',
  },
  submitButton: {
    height: theme.spacing(5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  resendButton: {
    textTransform: 'capitalize',
    fontWeight: '600',
    fontSize: 16,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  inputError: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  inputAdded: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

interface DigitVerifierProps {
  submitForm: (value: string) => void;
  isSubmitting: boolean;
  resendCode: () => void;
  error?: string;
}

const checkIsNum = (value: string) => /^\d+$/.test(value);
const VERIFICATION_CODE_LENGTH = 6;

const DigitVerifier: FC<DigitVerifierProps> = ({ submitForm, isSubmitting, resendCode, error }) => {
  const { inputContainer, input, submitButton, resendButton, inputError, inputAdded } = useStyles();

  const [focusIndex, setFocusIndex] = useState<number>(0);
  const [fieldValues, setFieldValues] = useState<string[]>([...new Array(VERIFICATION_CODE_LENGTH).fill('')]);
  const inputRef = useRef<HTMLInputElement>(null);

  const getReference = (index: number) => (index === focusIndex ? inputRef : null);

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!checkIsNum(value)) return;

    const valueToSet = value.length > 1 ? value[value.length - 1] : value;

    const newValues = [...fieldValues];

    const hasValue = newValues[focusIndex];
    const isLastDigit = focusIndex + 1 === VERIFICATION_CODE_LENGTH;

    if (hasValue && isLastDigit) return;

    if (!hasValue) {
      newValues[focusIndex] = valueToSet;
    } else {
      newValues[focusIndex + 1] = valueToSet;
    }

    setFieldValues([...newValues]);

    if (focusIndex + 1 < VERIFICATION_CODE_LENGTH) {
      setFocusIndex(focusIndex + 1);
    }
  };

  const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
    setFocusIndex(Number(event.target.name));
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [focusIndex]);

  useEffect(() => {
    if (error) {
      setFieldValues([...new Array(VERIFICATION_CODE_LENGTH).fill('')]);
    }
  }, [error]);

  const handleSubmitForm = () => {
    if (fieldValues.filter(Boolean).length !== VERIFICATION_CODE_LENGTH) return;
    submitForm(fieldValues.join(''));
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.clipboardData.items[0].getAsString((text: string) => {
      const newFieldValues = text.split('').slice(0, VERIFICATION_CODE_LENGTH).filter(checkIsNum);

      setFieldValues(newFieldValues);
    });
  };

  const handleBackspace = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== 'Backspace') return;
    if (focusIndex === 0 && fieldValues[focusIndex] === '') return;
    if (focusIndex === 0 && fieldValues[focusIndex] !== '') {
      const newState = [...fieldValues];
      newState[focusIndex] = '';
      setFieldValues([...newState]);
    } else {
      const newState = [...fieldValues];
      newState[focusIndex] = '';
      setFieldValues([...newState]);
      setFocusIndex(focusIndex - 1);
    }
  };

  return (
    <Grid onPaste={handlePaste} onKeyDown={handleBackspace} className={inputContainer}>
      <Grid container justifyContent="space-between" spacing={2}>
        {fieldValues.map((item: string, index: number) => (
          <Grid item xs={2} key={`${item}-${index * 2}`}>
            <input
              className={clsx(input, { [inputError]: error, [inputAdded]: item })}
              type="text"
              name={`${index}`}
              value={item || '0'}
              onChange={handleFieldChange}
              onFocus={handleOnFocus}
              ref={getReference(index)}
              tabIndex={index + 1}
              disabled={isSubmitting}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={index === 0}
            />
          </Grid>
        ))}
      </Grid>
      {error && <FormHelperText error>{error}</FormHelperText>}

      <Button
        loading={isSubmitting}
        onClick={handleSubmitForm}
        sx={{ width: '360px', marginTop: '50px!important' }}
        disabled={fieldValues.filter(Boolean).length !== VERIFICATION_CODE_LENGTH}
        className={submitButton}
      >
        Submit
      </Button>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="body1" align="center">
          Did not received the code?{' '}
        </Typography>
        <Button className={resendButton} onClick={resendCode} variant="text" color="primary">
          Click to resend
        </Button>
      </Box>
    </Grid>
  );
};

export default DigitVerifier;
