import { FC, ReactNode } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: spacing(2.5),
  },
  titleText: {
    width: 210,
    color: palette.grey[600],
  },
  titleTextWithIcon: {
    color: palette.grey[600],
  },
}));

type PanelRowProps = {
  title: string;
  titleAlignment?: string;
  additionalTitleElement?: ReactNode;
  children: ReactNode;
};

const PanelRow: FC<PanelRowProps> = ({ title, titleAlignment, additionalTitleElement = null, children }) => {
  const { root, titleText, titleTextWithIcon } = useStyles();

  const renderTitle = () =>
    additionalTitleElement ? (
      <Box display="flex" alignItems="center" gap="10px" width="210px">
        <Typography className={titleTextWithIcon} variant="subtitle1">
          {title}
        </Typography>
        {additionalTitleElement}
      </Box>
    ) : (
      <Typography className={titleText} variant="subtitle1">
        {title}
      </Typography>
    );

  return (
    <Box className={root} sx={{ alignItems: titleAlignment ?? 'center' }}>
      {renderTitle()}

      <Box width="100%">{children}</Box>
    </Box>
  );
};

export default PanelRow;
