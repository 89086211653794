import { FC } from 'react';
import { Box, IconButton, Stack, Typography, styled } from '@mui/material';
import { Close, EditOutlined } from '@mui/icons-material';
import { AvatarCard } from 'components/molecules';
import { Button, OrganizationTeamLabel } from 'components/atoms';
import { ORGANIZATION_DRAWER_TOP_MARGIN } from 'constants/componentSizes';

const Container = styled(Stack)(({ theme: { palette } }) => ({
  minHeight: `calc(100vh - ${ORGANIZATION_DRAWER_TOP_MARGIN}px)`,
  borderRight: `1px solid ${palette.divider}`,
  position: 'relative',
}));

const StyledButton = styled(Button)(({ theme: { palette, spacing } }) => ({
  width: '150px',
  border: `1px solid ${palette.divider}`,
  position: 'absolute',
  bottom: spacing(4),
}));

type OrganizationUserPanelProps = {
  user: OrganizationUser | null;
  onUserModalOpen: () => void;
  onClose: () => void;
};

const OrganizationUserPanel: FC<OrganizationUserPanelProps> = ({ user, onClose, onUserModalOpen }) => {
  if (!user) {
    return null;
  }

  const avatarCardDetails = {
    image: user.image,
    title: user.name,
    subtitle: user.role,
  };

  return (
    <Container>
      <AvatarCard
        details={avatarCardDetails}
        action={
          <IconButton onClick={onClose}>
            <Close sx={{ color: 'white' }} />
          </IconButton>
        }
      />

      <Stack pl={2} mt={2}>
        <Typography variant="body1" color="primary" sx={{ fontWeight: '600' }}>
          Location
        </Typography>
        <Typography mb={2} variant="body2">
          {user.address}
        </Typography>

        <Typography variant="body1" color="primary" sx={{ fontWeight: '600' }}>
          Contact details
        </Typography>
        <Typography variant="body2">{user.phone}</Typography>
        <Typography variant="body2" mb={2}>
          {user.email}
        </Typography>

        <Typography variant="body1" color="primary" sx={{ fontWeight: '600' }}>
          Joined
        </Typography>
        <Typography mb={2} variant="body2">
          {user.joinedAt}
        </Typography>

        {user?.teams && user?.teams?.length > 0 && (
          <>
            <Typography variant="body1" color="primary" sx={{ fontWeight: '600' }}>
              Teams
            </Typography>
            <Box sx={{ display: 'flex' }}>
              {user?.teams?.map((team: any) => (
                <OrganizationTeamLabel key={team.id} teamLabel={team.name} color={team.teamColor} />
              ))}
            </Box>
          </>
        )}

        <StyledButton startIcon={<EditOutlined />} variant="text" onClick={onUserModalOpen}>
          Edit user
        </StyledButton>
      </Stack>
    </Container>
  );
};

export default OrganizationUserPanel;
