import { FC, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Badge, Box, Divider, IconButton, Popper, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NotificationsOutlined } from '@mui/icons-material';
import { NotificationRow } from 'components/molecules';
import { Button } from 'components/atoms';
import { SharedDocumentsVar } from 'cache/DocumentCache';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  container: {
    width: 800,
    background: palette.background.paper,
    boxShadow: '3px 3px 5px 5px rgba(0,0,0,.25)',
    minHeight: 400,
    paddingBottom: spacing(2),
  },
  badge: {
    '& .MuiBadge-badge': {
      height: 16,
      minWidth: 16,
      fontSize: 12,
      right: 4,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(2),
  },
  notificationsContainer: {
    height: '100%',
    minHeight: '300px',
    maxHeight: '400px',
    overflowY: 'auto',
  },
  zeroNotifications: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 300,
  },
}));

const Notifications: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<any[]>([]);
  const styles = useStyles();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const checkNotifications = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const { sharedDocuments } = useReactiveVar(SharedDocumentsVar);

  useEffect(() => {
    if (sharedDocuments && sharedDocuments.length > 0) {
      const enhancedNotifications = sharedDocuments.map((document: any, index: number) => ({
        ...document,
        wasViewed: index > 2,
      }));
      setNotifications(enhancedNotifications);
    }
  }, [sharedDocuments]);

  const viewedNotifications = notifications.filter((notification) => !notification.wasViewed);

  const handleMarkAllAsRead = () => {
    setNotifications(notifications.map((notification) => ({ ...notification, wasViewed: true })));
  };

  const handleRemoveAll = () => {
    setNotifications([]);
  };

  const renderEmptyNotification = () => (
    <Box className={styles.zeroNotifications}>
      <Typography variant="h6">No notifications</Typography>
    </Box>
  );

  const renderNotifcations = () => (
    <Box className={styles.notificationsContainer}>
      {notifications.map((notification: any) => (
        <NotificationRow key={notification.document.id} notification={notification} />
      ))}
    </Box>
  );

  return (
    <>
      <IconButton aria-describedby={id} type="button" onClick={checkNotifications}>
        <Badge
          className={styles.badge}
          color="secondary"
          badgeContent={viewedNotifications.length > 0 ? viewedNotifications.length : null}
        >
          <NotificationsOutlined sx={{ fontSize: 30 }} />
        </Badge>
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <Stack className={styles.container}>
          <Box className={styles.header}>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" mr={2}>
                Notifications
              </Typography>
              {viewedNotifications.length > 0 && (
                <Typography variant="body2">{`${viewedNotifications.length} new notification${
                  viewedNotifications.length > 1 ? 's' : ''
                }`}</Typography>
              )}
            </Box>
            <Box>
              <Button variant="text" disabled={!viewedNotifications.length} onClick={handleMarkAllAsRead}>
                Mark all as read
              </Button>
              <Button variant="text" color="error" disabled={!notifications.length} onClick={handleRemoveAll}>
                Clear all
              </Button>
            </Box>
          </Box>
          <Divider />
          {notifications.length === 0 ? renderEmptyNotification() : renderNotifcations()}
        </Stack>
      </Popper>
    </>
  );
};

export default Notifications;
