import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Avatar } from 'components/atoms';
import { getTimeAgo } from 'utils/dateTime';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  container: {
    height: 80,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(1),
    cursor: 'pointer',
  },
  avatarContainer: {
    width: '15%',
    display: 'flex',
    justifyContent: 'center',
  },
  dateString: {
    color: palette.grey[700],
    fontWeight: 600,
  },
  boldText: {
    fontWeight: 600,
  },
  dotContainer: {
    width: '10%',
    display: 'flex',
  },
  dot: {
    width: '10px',
    height: '10px',
    backgroundColor: palette.primary.main,
    borderRadius: '50%',
    display: 'inline-block',
    margin: '0px auto',
  },
}));

type NotificationRowProps = {
  notification: any;
};

const NotificationRow: FC<NotificationRowProps> = ({ notification }) => {
  const navigate = useNavigate();
  const styles = useStyles();
  const { document, users, wasViewed } = notification;

  const handleNavigation = (id: string) => {
    navigate(`/document/${id}`);
  };

  return (
    <>
      <Box className={styles.container} onClick={() => handleNavigation(document.id)}>
        <Box className={styles.avatarContainer}>
          <Avatar size={50} firstName={users[0].firstName} lastName={users[0].lastName} />
        </Box>

        <Stack width="75%" sx={{ opacity: !wasViewed ? '1' : '0.5' }}>
          <Typography variant="body2">
            <span className={styles.boldText}>{`${users[0].firstName} ${users[0].lastName}`}</span> shared{' '}
            <span className={styles.boldText}>{document.name}</span> with you
          </Typography>
          <Typography variant="caption" className={styles.dateString}>
            {getTimeAgo(document.updatedAt)}
          </Typography>
        </Stack>

        <div className={styles.dotContainer}>{!wasViewed && <span className={styles.dot} />}</div>
      </Box>
      <Divider />
    </>
  );
};

export default NotificationRow;
