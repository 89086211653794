import { FC, ReactNode } from 'react';
import { Dialog, Card, CardHeader, IconButton, Divider, Grid, Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CloseOutlined } from '@mui/icons-material';
import clsx from 'clsx';

export interface ModalContainerProps {
  open: boolean;
  title: string | ReactNode;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  subheader?: string;
  dataCy?: string;
  minHeight?: boolean;
  children: ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  content: {
    padding: spacing(3),
  },
  withMinHeight: {
    minHeight: spacing(75),
  },
}));

const ModalContainer: FC<ModalContainerProps> = ({
  open,
  maxWidth = 'md',
  minHeight = false,
  title,
  dataCy,
  subheader,
  onClose,
  children,
}) => {
  const { content, withMinHeight } = useStyles();

  return (
    <Dialog open={open} maxWidth={maxWidth} data-cy={dataCy} fullWidth scroll="paper">
      <Paper>
        <Card>
          <CardHeader
            title={title}
            subheader={subheader}
            action={
              <IconButton data-cy="modal__close__button" onClick={onClose}>
                <CloseOutlined />
              </IconButton>
            }
          />
          <Divider />
          <Grid className={clsx({ [content]: true, [withMinHeight]: minHeight })}>{children}</Grid>
        </Card>
      </Paper>
    </Dialog>
  );
};

export default ModalContainer;
