import { FC } from 'react';
import { Stack, Typography, styled } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { AvatarCard } from 'components/molecules';
import { Button } from 'components/atoms';
import { ORGANIZATION_DRAWER_TOP_MARGIN, ORGANIZATION_DRAWER_WIDTH } from 'constants/componentSizes';

const Container = styled(Stack)(({ theme: { palette } }) => ({
  width: ORGANIZATION_DRAWER_WIDTH,
  minHeight: `calc(100vh - ${ORGANIZATION_DRAWER_TOP_MARGIN}px)`,
  borderRight: `1px solid ${palette.divider}`,
  position: 'relative',
}));

const StyledButton = styled(Button)(({ theme: { palette, spacing } }) => ({
  width: '80px',
  border: `1px solid ${palette.divider}`,
  position: 'absolute',
  bottom: spacing(4),
}));

type OrganizationDetailsProps = {
  organization: any;
};

const OrganizationDetails: FC<OrganizationDetailsProps> = ({ organization }) => {
  const avatarCardDetails = {
    image: organization.logo,
    title: organization.name,
    subtitle: organization.website,
  };

  return (
    <Container>
      <AvatarCard details={avatarCardDetails} />

      <Stack pl={2} mt={2}>
        <Typography variant="body1" color="primary" sx={{ fontWeight: '600' }}>
          Description
        </Typography>
        <Typography mb={2} variant="body2">
          {organization.description}
        </Typography>

        <Typography variant="body1" color="primary" sx={{ fontWeight: '600' }}>
          Industry
        </Typography>
        <Typography mb={2} variant="body2">
          {organization.industry}
        </Typography>

        <Typography variant="body1" color="primary" sx={{ fontWeight: '600' }}>
          Headquarters
        </Typography>
        <Typography mb={2} variant="body2">
          {organization.address}
        </Typography>

        <Typography variant="body1" color="primary" sx={{ fontWeight: '600' }}>
          Contacts
        </Typography>
        <Typography variant="body2">{organization.phone}</Typography>
        <Typography variant="body2">{organization.email}</Typography>

        <StyledButton startIcon={<EditOutlined />} variant="text">
          Edit
        </StyledButton>
      </Stack>
    </Container>
  );
};

export default OrganizationDetails;
