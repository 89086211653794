import { FC, useRef, useState } from 'react';
import { Box, DialogActions, Grid, IconButton } from '@mui/material';
import { ZoomIn, ZoomOut, RotateLeft, RotateRight } from '@mui/icons-material';
import AvatarEditor from 'react-avatar-editor';
import ModalContainer from 'components/ModalContainer/ModalContainer';
import { Button } from 'components/atoms';

interface ImageEditorProps {
  file: File | string;
  open: boolean;
  onClose: () => void;
  onEditDone: (file: File) => void;
  loading?: boolean;
}

const ImageEditor: FC<ImageEditorProps> = ({ file, open, onClose, onEditDone, loading }) => {
  const editorRef = useRef<AvatarEditor | null>(null);
  const [rotation, setRotation] = useState<number>(0);
  const [scaling, setScaling] = useState<number>(1);

  const rotateRight = () => {
    setRotation((prevState) => prevState + 90);
  };

  const rotateLeft = () => {
    setRotation((prevState) => prevState - 90);
  };

  const scaleUp = () => {
    setScaling((prevState) => prevState * 1.1);
  };

  const scaleDown = () => {
    setScaling((prevState) => prevState / 1.1);
  };

  const handleEditDone = () => {
    const image = editorRef.current?.getImage();
    image?.toBlob((blob: Blob | null) => {
      if (blob) {
        const editedFile = new File([blob as BlobPart], (file as File).name, {
          type: blob.type,
        });
        onEditDone(editedFile);
      }
    });
  };

  return (
    <ModalContainer title="Edit avatar" open={open} onClose={onClose}>
      <Box textAlign="center" marginBottom={2}>
        <AvatarEditor
          ref={editorRef}
          image={file}
          border={0}
          rotate={rotation}
          borderRadius={1000}
          scale={scaling}
          disableBoundaryChecks
        />
        <Grid container justifyContent="center">
          <IconButton color="primary" onClick={scaleUp}>
            <ZoomIn />
          </IconButton>
          <IconButton color="primary" onClick={scaleDown}>
            <ZoomOut />
          </IconButton>
          <IconButton color="primary" onClick={rotateLeft}>
            <RotateLeft />
          </IconButton>
          <IconButton color="primary" onClick={rotateRight}>
            <RotateRight />
          </IconButton>
        </Grid>
      </Box>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button loading={loading} onClick={handleEditDone}>
          Confirm
        </Button>
      </DialogActions>
    </ModalContainer>
  );
};

export default ImageEditor;
