import { FC, ReactNode } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';

type SettingsRowProps = {
  title: string;
  description: string;
  withDivider?: boolean;
  content: ReactNode;
};

const SettingsRow: FC<SettingsRowProps> = ({ title, description, withDivider = false, content }) => {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack sx={{ width: '280px', marginRight: '100px' }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
          <Typography variant="body2">{description}</Typography>
        </Stack>

        {content}
      </Box>
      {withDivider && <Divider sx={{ marginTop: 3, marginBottom: 3 }} />}
    </>
  );
};

export default SettingsRow;
