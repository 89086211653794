import { FC } from 'react';
import { IconButton, Stack, Typography, styled } from '@mui/material';
import { Close, EditOutlined } from '@mui/icons-material';
import { AvatarCard, UserAvatar } from 'components/molecules';
import { Button, OrganizationTeamLabel } from 'components/atoms';
import { ORGANIZATION_DRAWER_TOP_MARGIN, ORGANIZATION_DRAWER_WIDTH } from 'constants/componentSizes';

const Container = styled(Stack)(({ theme: { palette } }) => ({
  width: ORGANIZATION_DRAWER_WIDTH,
  minHeight: `calc(100vh - ${ORGANIZATION_DRAWER_TOP_MARGIN}px)`,
  borderRight: `1px solid ${palette.divider}`,
  position: 'relative',
}));

const StyledButton = styled(Button)(({ theme: { palette, spacing } }) => ({
  width: '150px',
  border: `1px solid ${palette.divider}`,
  position: 'absolute',
  bottom: spacing(4),
}));

type OrganizationTeamPanelProps = {
  team: any;
  onClose: () => void;
};

const OrganizationTeamPanel: FC<OrganizationTeamPanelProps> = ({ team, onClose }) => {
  if (!team) {
    return null;
  }

  const avatarCardDetails = {
    image: team.logo,
    other: <OrganizationTeamLabel teamLabel={team.name} color={team.teamColor} />,
  };

  console.log('team', team);

  return (
    <Container>
      <AvatarCard
        details={avatarCardDetails}
        action={
          <IconButton onClick={onClose}>
            <Close sx={{ color: 'white' }} />
          </IconButton>
        }
      />

      <Stack pl={2} mt={2}>
        <Typography variant="body1" color="primary" sx={{ fontWeight: '600' }}>
          Description
        </Typography>
        <Typography mb={2} variant="body2">
          {team.teamDescription}
        </Typography>

        <Stack>
          <Typography variant="body1" color="primary" sx={{ fontWeight: '600', mb: 1 }}>
            Teams leads
          </Typography>
          <Stack>
            {team.teamMembers
              .filter((mem: any) => mem.id === team.teamLead)
              .map((member: any) => (
                <UserAvatar
                  firstName={member.name.split(' ')[0]}
                  lastName={member.name.split(' ')[1]}
                  profileImage={member.image}
                />
              ))}
          </Stack>
        </Stack>

        <StyledButton startIcon={<EditOutlined />} variant="text">
          Manage team
        </StyledButton>
      </Stack>
    </Container>
  );
};

export default OrganizationTeamPanel;
