import { FC, ReactNode } from 'react';
import { Dialog, CardHeader, IconButton, Divider, DialogTitle, DialogContent, styled } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

export interface ModalContainerProps {
  open: boolean;
  title: string | ReactNode;
  subheader?: string;
  width?: string | number;
  children: ReactNode;
  onClose: () => void;
}

const StyledDialog = styled(Dialog)(({ width }: { width: string | number }) => ({
  '& .MuiDialog-paper': {
    width,
  },
}));

const ModalContainer: FC<ModalContainerProps> = ({ width = 600, open, title, subheader, onClose, children }) => {
  return (
    <StyledDialog open={open} width={width} maxWidth={false}>
      <DialogTitle>
        <CardHeader
          title={title}
          subheader={subheader}
          sx={{ padding: 0 }}
          action={
            <IconButton onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          }
        />
      </DialogTitle>

      <DialogContent sx={{ maxHeight: '80vh', overflowY: 'auto' }}>{children}</DialogContent>
    </StyledDialog>
  );
};

export default ModalContainer;
