import { ApolloError, makeVar } from '@apollo/client';

// DocumentViewerVar
export interface IDocumentViewerVar {
  documentId: string;
  versionId: string;
  versionName: string;
  currentSegmentId: string;
  onError: (error?: ApolloError | string) => void;
  canEdit: boolean;
  documentType: string;
  clickedVariable: {
    variableId: string;
    previousVariableId: string;
  };
  clickedVr: {
    snippetId: string;
  };
  clickedComment: {
    commentId: string;
  };
}

export const DocumentViewerVar = makeVar<IDocumentViewerVar>({
  documentId: '',
  currentSegmentId: '',
  versionId: '',
  versionName: '',
  onError: () => {},
  documentType: '',
  canEdit: true,
  clickedVariable: {
    variableId: '',
    previousVariableId: '',
  },
  clickedVr: {
    snippetId: '',
  },
  clickedComment: {
    commentId: '',
  },
});

// Document Rename Modal
export interface IDocumentRenameVar {
  documentId: string;
  showDocumentRenameModal: boolean;
}

export const DocumentRenameVar = makeVar<IDocumentRenameVar>({
  documentId: '',
  showDocumentRenameModal: false,
});

// Smart Document
export interface ISmartDocumentVar {
  effectedLinks: Record<string, string>[];
}

export const SmartDocumentVar = makeVar<ISmartDocumentVar>({
  effectedLinks: [],
});

export const DownloadDocumentEventIdVar = makeVar<string>('');

export const SharedDocumentsVar = makeVar<any>({
  sharedDocuments: [],
});
