import { FC, useState, useCallback, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { AppHeaderVar } from 'cache/App/AppHeaderCache';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Typography,
  SwipeableDrawer,
  List,
  ListItemText,
  Box,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import { ArrowBack, CloseOutlined, Dashboard, Description, Logout, Help, Groups } from '@mui/icons-material';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import { ReactComponent as LogoSmall } from 'assets/Logo/logo_small.svg';
import { ActiveUserAvatar, Breadcrumbs } from 'components/molecules';
import { useAuth } from 'hooks/useAuth';
import { Notifications } from 'components/organisms';

const EDITOR_TYPE_TO_TITLE: Record<string, string> = {
  TEMPLATE: 'TEMPLATE EDITOR',
  SMART: 'DOCUMENT EDITOR',
  DEFINITION_WIZARD: 'DEFINITION WIZARD',
};

const pathnameToBreadcrumbs: any = {
  '/': ['Dashboard'],
  '/help-center': ['HELP CENTER'],
  '/data-room': ['Data Room'],
  '/settings': ['Settings'],
  '/select-document-type': ['Document creation'],
  '/select-creation-method/smart': ['Document creation', 'Smart Document'],
  '/select-creation-method/template': ['Document creation', 'Template'],
  '/template-list': ['Document creation', 'Smart Document', 'Assisted creation'],
  'template-import': ['Document creation', 'Smart Template Import'],
  'smart-import': ['Document creation', 'Smart Document Import'],
};

const NavigationBar: FC = () => {
  const {
    signOut,
    user: { firstName, lastName },
  } = useAuth();

  const { title, documentName, versionName, editorType, organization } = useReactiveVar(AppHeaderVar);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Tab') {
      return;
    }

    setDrawerOpen(open);
  };

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    setDrawerOpen(false);
  }, [location.pathname]);

  const path = location.pathname.trim();

  const breadCrumbs: string[] = pathnameToBreadcrumbs[path ?? ''] ?? [];

  const hasBackButton = breadCrumbs.length > 1;

  const onActionClick = useCallback(() => {
    if (breadCrumbs.length > 0) {
      navigate(-1);
    } else {
      navigate('/data-room');
    }
  }, [breadCrumbs]);

  const mainTitle = editorType ? EDITOR_TYPE_TO_TITLE[editorType] : title;

  const renderHeaderWithoutBreadcrumbs = () => {
    return (
      <>
        {mainTitle && mainTitle?.length > 0 && (
          <Typography
            sx={{
              marginRight: '0.75rem',
              fontFamily: "'Mulish', sans-serif",
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
            variant="body1"
            color="primary"
          >
            {mainTitle}
          </Typography>
        )}
        {documentName && (
          <Typography
            data-cy="header__title"
            sx={{
              marginRight: '0.75rem',
              fontFamily: "'Mulish', sans-serif",
              fontSize: '16px',
              '@media (min-width: 1024px)': {
                fontSize: '15px',
              },
              '@media (min-width: 1280px)': {
                fontSize: '18px',
              },
              marginLeft: '10px',
            }}
            variant="body1"
            color="textPrimary"
          >
            {documentName}
            {versionName && <>; {versionName}</>}
          </Typography>
        )}
        {organization && (
          <Typography
            sx={{
              marginRight: '0.75rem',
              fontFamily: "'Mulish', sans-serif",
              fontSize: '16px',
              '@media (min-width: 1024px)': {
                fontSize: '15px',
              },
              '@media (min-width: 1280px)': {
                fontSize: '18px',
              },
            }}
            variant="body1"
            color="textPrimary"
          >
            {organization}
          </Typography>
        )}
      </>
    );
  };

  const renderHeader = () => {
    if (!breadCrumbs.length) {
      return <>{renderHeaderWithoutBreadcrumbs()}</>;
    }

    if (breadCrumbs.length > 1) {
      return <Breadcrumbs breadcrumbs={breadCrumbs} />;
    }

    return (
      <>
        <Typography
          variant="h6"
          color="primary"
          sx={{
            fontFamily: "'Mulish', sans-serif",
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
          style={{ fontSize: '18px' }}
        >
          {breadCrumbs[0]}
        </Typography>
      </>
    );
  };

  const isDashboard = breadCrumbs[0] === 'Dashboard';

  return (
    <>
      <AppBar
        id="app-bar"
        position="static"
        sx={{
          '&.MuiPaper-root': {
            boxShadow: 'none',
            borderBottom: '1px solid #E9ECF1',
          },
        }}
      >
        <Toolbar
          sx={{
            '&.MuiToolbar-root': {
              minHeight: 50,
              paddingLeft: 0,
            },
            display: 'flex',
            gap: 2,
            backgroundColor: 'white',
          }}
        >
          <Button
            variant="contained"
            title="Template.io"
            onClick={toggleDrawer(true)}
            sx={{
              height: 50,
              minWidth: 60,
              paddingLeft: 1,
              paddingRight: 1,
              borderRadius: '0 12px 12px 0',
            }}
          >
            <LogoSmall color="primari" />
          </Button>
          {hasBackButton && (
            <IconButton onClick={onActionClick}>
              <ArrowBack />
            </IconButton>
          )}
          <Box
            id="bread-crumbs"
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: isDashboard ? 'space-between' : 'flex-start',
            }}
          >
            {renderHeader()}

            {isDashboard && <Notifications />}
          </Box>
        </Toolbar>
      </AppBar>

      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={0}
        color="white"
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: '#0F4C81',
            borderTopRightRadius: 24,
            boxShadow: 'none',
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" m={3} ml={2}>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseOutlined sx={{ color: 'white' }} />
          </IconButton>
          <LogoSmall color="white" width={22} height={34} />
        </Box>

        <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
          <Box>
            <List component="nav" sx={{ color: 'white' }}>
              <ListItemButton component={Link} to="/">
                <ListItemIcon>
                  <Dashboard sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>

              <ListItemButton component={Link} to="/data-room">
                <ListItemIcon>
                  <Description sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Data Room" />
              </ListItemButton>

              <ListItemButton component={Link} to="/organization">
                <ListItemIcon>
                  <Groups sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Organization" />
              </ListItemButton>
            </List>
          </Box>

          <Box pr={3} pl={3} pt={2} pb={2} sx={{ backgroundColor: '#557193' }}>
            <Box
              component={Link}
              to="/settings"
              display="flex"
              alignItems="center"
              mb={1}
              sx={{ textDecoration: 'none' }}
            >
              <Box mr={2}>
                <ActiveUserAvatar size={40} />
              </Box>
              <Typography variant="body1" color="common.white">
                {firstName} {lastName}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <IconButton onClick={signOut}>
                <Logout sx={{ color: 'white' }} />
              </IconButton>
              <IconButton onClick={() => navigate('/help-center')}>
                <Help sx={{ color: 'white' }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default NavigationBar;
